import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable'
import styles from './Audioplayer.module.sass'

import Icon from 'components/Icon'

const AudioPlayer = ({ data, onPlay, playingID }) => {
    const [ isPlaying, setIsPlaying ] = useState(false)
    const [ isDragging, setIsDragging ] = useState(false)
    const [ isDraggedMin, setIsDraggingMin ] = useState(false)

    const [ x, setX ] = useState(0)
    //const [ width, setWidth ] = useState(0)

    const player = useRef(null)
    const dragger = useRef(null)
    const audio = useRef(null)
    const interval = useRef(null);

    const test = useRef(false)
    const width = useRef(0)


    useEffect(() => {
        setSizes()

        window.addEventListener('resize', setSizes)

		return () => {
			window.removeEventListener('resize', setSizes)
        }

    }, [])

    useEffect(() => {

        if(isPlaying && playingID && playingID !== data.id) {
            togglePlay(); 
        }

    }, [playingID])

    const setSizes = () => {
        
        let rect = player.current.getBoundingClientRect();
        let rect2 = dragger.current.getBoundingClientRect();

        console.log(rect2)

        //setWidth(rect.width-rect2.width)
        width.current = rect.width-rect2.width;
    }


    const togglePlay = () => {
        if (isDraggedMin) {
            return
        }

        if (!isPlaying) {

            if (!audio.current) {
                audio.current = new Audio(data.file);
            }

            audio.current.onended = () => {
                console.log('ended')
                //togglePlay();
                audio.current.currentTime = 0;
                setX(0)
                setIsPlaying(false)
                clearInterval(interval.current);
            };

            //this.props.onPlay(this.props.data.id);

            audio.current.play()

            interval.current = setInterval(
                () => setPosition()
            , 100);

            onPlay(data.id);

        } else {
            audio.current.pause()

            clearInterval(interval.current);
        }

        setIsPlaying(!isPlaying)
    }

    

    const onDragStart = () => {
        console.log('onDragStart')
        setIsDragging(true)
        test.current = true
    }

    const onDrag = (e, ui) => {
        setIsDraggingMin(isDraggedMin ? true : Math.abs(ui.deltaX) > 3 ? true : false)
        setX(x + ui.deltaX)
        
    }

    const timeout = useRef(false)
    
    const onDragStop = () => {
        console.log('onDragStop')
        clearTimeout(timeout.current);

        if (isDraggedMin) {
            let position = x / width.current * audio.current.duration

            audio.current.currentTime = position
        } else {
            togglePlay()
        }

        timeout.current = setTimeout(() => {

            setIsDraggingMin(false)
            setIsDragging(false)
            console.log('setTimeout')
            test.current = false
        }
        , 50);
    }

    const setPosition = () => {
        console.log(isDragging)
        if (test.current) {
            console.log('canceld')
            return false
        }

        //console.log(audio.current.currentTime,  audio.current.duration)

        let position = audio.current.currentTime / audio.current.duration * width.current

        //console.log(width)

        setX(position)
    }

    return (
        <div className={styles.player + ' audioplayer'}>
            {data.file && 
                <div className="track">
                    <span ref={player}>
                        <Draggable
                            position={{ x: x, y: 0 }}
                            axis="x"
                            bounds={{ left: 0, right: width.current }}
                            onStart={onDragStart}
                            onDrag={onDrag}
                            onStop={onDragStop}
                        >
                            <div className="position" ref={dragger}>
                                <Icon type={isPlaying ? 'pause' : 'play'} />
                            </div>
                        </Draggable>
                    </span>
                </div>
            }
            {data.text && 
                <p>{data.text}</p>
            }
        </div>
    );
};

export default AudioPlayer;