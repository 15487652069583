import React, { useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { getPage } from 'actions'

import Loading from 'components/loading'

import Map from './contents/map'
import Text from './contents/text'


import Home from './contents/home'
import Event from './contents/event'
import Blog from './contents/blog'
import Location from './contents/locations'
import Teaserlist from './contents/teaserlist'

import styles from './Page.module.sass'


const contents = {
    text: Text,
    map: Map,
    home: Home,
    event: Event,
    blog: Blog,
    location: Location,
    teaserWithSubpages: Teaserlist
}

const Page = ({location, getPage, data, places, path, type, style}) => {

    //const [lcocat] = useRef(location.pathname)

    useEffect(() => {
        if(data && !data.selfScroll) {
            window.scrollTo(0, 0)
        }

        console.log(path)
        if(!data) { 
            getPage(path)
        }

    }, [])


    useEffect(() => {
		if(data) {
			document.title = data.title.replace(/&#038;/g, "&").replace(/&#8211;/g, "–") + ' – Rudolf Mosse';
		}		
    }, [data])
    
    /*
    useEffect(() => {
        
        console.log('try out')
        console.log(location.pathname)
        if(data) console.log(data.path)
       if(data && data.path !== location.pathname) {
           console.log('out')
       }
    }, [data, location])
    */

    //if(!data) return null

    const Content = data ? contents[data.template] : null;

    let classNames = [styles.page ]
    type && classNames.push(type)
    
    return (
        <div className={classNames.join(' ')} style={style}>
            {!data ?
                <Loading />
            :
                <CSSTransition
                    in
                    classNames="animate-content"
                    appear={true}
                    timeout={500}>
                        <Content 
                            data={data.content}
                            places={places}
                            theme={data.theme}
                        />
                </CSSTransition>	
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { places } = state.config
    const { pages } = state.pages
    const data = pages.find(page => page.path === props.path) 

	return {
        data,
        places
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)), 
})

function propsAreEqual(prev, next) {
  
    if(prev.type !== next.type) {
        return false
       
    }
    

    if(!prev.places.length && next.places.length) {
        return false    
    }

	if(prev.data) {
		return true
    }  
}
/*
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Page, propsAreEqual));
*/


const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(Page, propsAreEqual));