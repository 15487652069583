import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Event.module.sass'

import Content from 'components/page/parts/content'

const Index = ({data}) => {
        
    return (
        <div className={(styles.event + ' center')}>
            <div className="sub">
                
            </div>
            <div className="main">
                {data.map((event, i) => {
                    
                    return (
                        <div id={event.name}>
                            <div className="center text">
                                <h2>{event.title}</h2>
                            </div>
                            <Content data={event.content} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Index;