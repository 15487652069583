import React, { Component } from 'react'

const Icon = ({type}) => {
    function getIcon() {

        switch (type) {
            case 'arrow':
                return (
                    <svg viewBox="0 0 34.34 63.02">
                        <polyline points="32.92 1.41 2.83 31.51 32.92 61.6" style={{fill: 'none', stroke: '#00a3ff', strokeWidth: '4px'}} />
                   </svg>
    
                )

            case 'play':
                return (
                    <svg viewBox="-1 0 8.48 16.96">
                        <polygon points="0 16.96 8.48 8.48 0 0 0 16.96" fill="#00a3ff" />
                    </svg>
                )

            case 'pause':
                return (
                    <svg viewBox="0 0 11.55 16.97">
                        <rect width="3.69" height="16.97" fill="#00a3ff" />
                        <rect x="7.86" width="3.69" height="16.97" fill="#00a3ff" />
                    </svg>
                )
        }
    }
    
    return (
        getIcon()
    )
}

export default Icon;