import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import { getConfig } from 'actions'
import styles from './Navigation.module.sass'

const Group = withRouter(({ location, data, setMarker, places, open }) => {
    const [height, setHeight] = useState(0)
    const textRef = useRef(null)
    const subMenuRef = useRef(null)
    //const pathMain = data.children.length ? data.children[0].path : data.path
    const activeStyle = {  }

    const isActive = (path) => (
        location.pathname.indexOf(path) !== -1
    )

    useEffect(() => {
        if(isActive(data.path)) {

            const { x, width } = textRef.current.getBoundingClientRect()

            setMarker(x, width)
        }
       
    }, [textRef, location])

    const handleClick = (e) => {
        if(open && data.children) {
            setHeight(
                height === 0 ? subMenuRef.current.clientHeight : 0
            )

            e.preventDefault()
        }


    }

    return (
        <li>
            <NavLink
                to={data.path}
                isActive={() => isActive(data.path)}
                activeStyle={activeStyle}
                onClick={handleClick}>
                <span ref={textRef}>
                    {data.title}
                </span>  
            </NavLink>
            {data.children==='places' &&
                <div style={{ height: `${height}px` }}>
                    <ul ref={subMenuRef}>
                        {places.map((place, i) => (
                            <li key={i}><NavLink to={place.path}>{place.title}</NavLink></li>
                        ))}
                    </ul>
                </div>
            }
        </li>
    )
})

const Navigation = withRouter(({ navigation, getConfig, location, places }) => {
    const [ open, setOpen ] = useState(false)
    const ref = useRef(null);
    const [ marker, setMarker ] = useState({x: 0, width: 0});
    const [ title, setTitle ] = useState('') 

    const navigationAll = [...navigation.main].concat([...navigation.meta])

    useEffect(() => {
        getConfig()
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)   
        return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
    }, [open])

    useEffect(() => {
        if(open) setOpen(false)

        if(location.pathname === '/') {
            setMarker({x: 0, width: 0})
        } 

    }, [location])

    useEffect(() => {

        let titleNew = '';

        if(location.pathname !== '/') {
     
            let test = navigationAll.filter(entry => location.pathname.indexOf(entry.path) > -1)[0]

            if(test) titleNew = test.title
        }

        setTitle(titleNew);


    }, [location, navigation])

    const handleOutsideClick = (event) => {
		if (open && ref.current && !ref.current.contains(event.target)) {
            setOpen(false)
		}
    }
    
    const calculateMarker = (x, width) => {

        const test = ref.current.getBoundingClientRect()

        setMarker({x: x - test.x, width: width })
    }

    const classes = [styles.navigation]
    open && classes.push('show-navigation')

    return (
        <div className={classes.join(' ')}>
            <NavLink to="/" className="home">
                <span />       
            </NavLink>
            <span className="title">{title}</span>
            <span
                className="menu" 
                onClick={() => setOpen(!open)}>
                <span />
            </span>
            <div>
                <div ref={ref}>
                    <ul>
                        {navigation.main.map((entry, i) => (
                            <Group
                                data={entry}
                                key={i} 
                                setMarker={(x, width) => calculateMarker(x, width)} 
                                places={places}
                                open={open}
                            />
                        ))}
                    </ul>
                    <ul>
                        {navigation.meta.map((entry, i) => (
                            <Group
                                data={entry}
                                key={i} 
                                setMarker={(x, width) => calculateMarker(x, width)} 
                                places={places}
                                open={open}
                            />
                        ))}
                    </ul>
                    <span style={{left: `${marker.x}px`, width: `${marker.width}px`}} />
                </div>
            </div>
        </div>
    )
})

const mapStateToProps = (state, props) => {
    const { navigation, places } = state.config

    return {
        navigation,
        places
    }
}

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);