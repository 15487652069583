import React, { useState } from 'react';

import Images from 'components/page/parts/images'
import Slider from 'components/page/parts/slider'
import AudioPlayer from 'components/page/parts/audioplayer'
import styles from './Content.module.sass'

const Content = ({ data, theme }) => {
    const [ playingID, setPlayingID ] = useState(null);

    let classNames = [styles.content]
    theme && classNames.push(theme)

    return (
        <div className={classNames.join(' ')}>
            {data.map((content, i) => {
                switch (content.type) {
                    case 'text':

                        return (
                            <div className="center text" key={i}>
                                <div className="rich" dangerouslySetInnerHTML={{ __html: content.text }}>
                                </div>
                            </div>
                        )

                    case 'images':

                        return (
                            <Images data={content.images} key={i} />
                        )

                    case 'slider':

                        return (
                            <Slider data={content.slides} key={i} />
                        )

                    case 'audio':

                        if(!content.file) return null
                        
                        return (
                            <div className="center text" key={i}>
                                <AudioPlayer data={content} playingID={playingID} onPlay={(id) => setPlayingID(id)} />
                            </div>
                        )

                }

            })}
        </div>
    );
};

export default Content;