import { pageConstants, configConstants } from '../constants';

const getConfig = () => ({
    type: configConstants.GETCONFIG_REQUEST
});

const getPage = path => ({
    type: pageConstants.GETPAGE_REQUEST,
    path,
});

export {
    getConfig,
    getPage
}