import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import styles from './Overlay.module.sass'

let scrollTop = null

const Overlay = ({active, history, location}) => {
    const [visible, setVisible] = useState(false)
    const [ image, setImage ] = useState(null)

    useEffect(() => {
        if (active && !visible) {

            scrollTop = window.scrollY || window.pageYOffset;

            let height = document.documentElement.scrollHeight;
            //console.log(height, scrollTop, window.innerHeight)

            let test = (scrollTop + window.innerHeight / 2) / height * 100
            //console.log(test)

            let styleTest = `transform-origin: center ${test}%; position: fixed; width: 100%; top: -${scrollTop}px`;

            //console.log(styleTest)

            document.getElementById('content').style.cssText = styleTest;
            document.getElementById('content').classList.add("test");

            setVisible(true)
           
        }

        if (!active && visible) {

            document.getElementById('content').classList.remove("test");

            setTimeout(() => {
                setVisible(false)
                setImage(null)
                document.getElementById('content').style.cssText = ''
                window.scroll(0, scrollTop)
            }, 300);
        }
    }, [active, visible])

    useEffect(() => {

        if(!image) {

            const imageNew = location.state && location.state.image ? location.state.image : null

            if(imageNew) {
                setImage(imageNew)
            }
        }

    }, [location])

    const handleClose = () => {
        history.push(location.pathname)
    }

    if (!visible) return null

    return (
        <div className={styles.overlay + (active ? ' in' : ' out')}>
            {image && 
                <div className="image" style={{backgroundImage: `url(${image.url})`}}>
                </div>
            }
            <span className="close" onClick={handleClose}>
                <span />
                <span />
            </span>    
        </div>
    );
};

export default withRouter(Overlay);