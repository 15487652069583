import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./Map.module.sass";
import MarkerClusterGroup from "react-leaflet-markercluster";

export const icon = new divIcon({
  html: `<div>ss</div>`,
});

const Index = withRouter(({ location, history, places }) => {
  const [activeLocation, setActiveLocation] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);

  const [viewport, setViewport] = useState({
    center: [45.4, -75.7],
    zoom: 15,
  });

  useEffect(() => {
    let filtered = places.filter((place) => place.lat !== null);

    if (filtered.length) {
      let place;

      if (location.state) {
        //alert(location.state)
        const index = places.findIndex(
          (_place) => _place.path === location.state
        );

        if (places[index].lat) {
          place = places[index];
          setActiveLocation(index);
        }
      }

      if (!place) place = filtered[0];

      setViewport({
        center: [place.lat, place.lng],
        zoom: 15,
      });
    }

    setFilteredPlaces(filtered);
  }, [places]);

  console.log(places);

  return (
    <div className={styles.map}>
      <div className="sub">
        <ul
          onMouseOver={() => setMouseOver(true)}
          onMouseOut={() => setMouseOver(false)}
        >
          {places.map((place, i) => (
            <li>
              <span
                className={i === activeLocation && !mouseOver ? "active" : ""}
                onClick={() => {
                  if (place.lat) {
                    setViewport({
                      center: [place.lat, place.lng],
                      zoom: viewport.zoom,
                    });
                    setActiveLocation(i);
                  }
                }}
              >
                {place.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="main">
        <Map viewport={viewport}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            detectRetina
          />

          <MarkerClusterGroup
            iconCreateFunction={function (cluster) {
              const clusterChildMarkers = cluster.getAllChildMarkers(); // list of all markers

              console.log(clusterChildMarkers);

              let html = "";

              clusterChildMarkers.forEach((event, i) => {
                console.log(i);
                html += `<div style="top: ${i * -10}px; left: ${
                  i * 10
                }px; z-index:${i * -1}">${event.options.title}</div>`;
              });

              return new divIcon({
                html: `${html}`,
                className: "my-div-icon",
              });
            }}
          >
            {filteredPlaces.map((place, i) => (
              <Marker
                key={i}
                position={[place.lat, place.lng]}
                onClick={() => {
                  history.push(place.path);
                }}
                title={place.title}
                icon={
                  new divIcon({
                    className: "my-div-icon",
                    html: `<div>${place.title}</div>`,
                  })
                }
              />
            ))}
          </MarkerClusterGroup>
        </Map>
      </div>
    </div>
  );
});

export default Index;
