import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Loading from 'components/loading'
import { getPage } from 'actions'
import { CSSTransition } from 'react-transition-group'

import Content from 'components/page/parts/content'

import styles from '../../Page.module.sass'
import styles2 from './Location.module.sass'

const Location = ({ location, getPage, data, path, type, style }) => {

    useEffect(() => {
        console.log('load page')
        window.scrollTo(0, 0)
        if (!data) {
            getPage(path)
        }
    }, [])

    let classNames = [styles.subpage, styles2.location]
    type && classNames.push(type)

    let marginBottom = 0 
    
    if(data && data.content.length) {
        marginBottom = (data.content[0].type === 'images' || data.content[0].type === 'slider')  ? -30 : 30
    }

    return (
        <div className={classNames.join(' ')} style={style}>
            {!data &&
                <Loading />
            }
            {data &&
                <CSSTransition
                    in
                    classNames="animate-content"
                    appear={true}
                    timeout={500}>
                    <div>
                        <div className="center text" style={{ zIndex: 1, position: 'relative', marginBottom: `${marginBottom}px` }}>
                            <h1 className="border"><Link to={{pathname: `/karte/`, state: location.pathname }}>{data.title}</Link></h1>
                        </div>
                        <Content data={data.content} theme="black" />
                    </div>
                </CSSTransition>
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { pages } = state.pages
    const data = pages.find(page => page.path === props.path)

    return {
        data
    }
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)),
})

function propsAreEqual(prev, next) {

    if (prev.type !== next.type) {
        return false

    }

    if (prev.data) {
        return true
    }

    console.log('cancel render')
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Location, propsAreEqual)));