import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Event.module.sass'

import Images from 'components/page/parts/images'
import { useEffect } from 'react';

import Content from 'components/page/parts/content'

const Index = ({data}) => {


    useEffect(() => {

        const now = new Date();

        let closest = Infinity;
        let closestEvent = null

        data.forEach( event => {
            
            var numbers = event.date.match(/\d+/g); 
            var date = new Date(numbers[2], numbers[1]-1, numbers[0]);

            //console.log(numbers)
            //console.log(date >= now)

            if (date >= now && ( date < closest)) {
                closest = date;
                closestEvent = event;
            }
        });

        console.log('try jump')

        setTimeout(() => { 
            //console.log('timeout')
            //console.log(closestEvent)
           
        }, 1);

        if(closestEvent) {
            console.log('jump')
            console.log(closestEvent.name)
            document.getElementById(closestEvent.name).scrollIntoView({})
            //window.scrollTo(0, 500)
        }

       
        //console.log(closestEvent);

    }, [])

    console.log('render')

    return (
        <div className={(styles.event + ' center')}>
            <div className="sub">
                <ul>
                    {data.map((event, i) => {
                        return <li key={i}><span onClick={() => {

                            if (document.getElementById(event.name)) {
                                
                                // Check if there is a hash and if an element with that id exists
                                document.getElementById(event.name).scrollIntoView({behavior: "smooth"})
                            }

                        }}>{event.date}</span></li>
                    })}
                </ul>
            </div>
            <div className="main">
                {data.map((event, i) => {

                    let text = `<h3>${event.title}</h3>${event.text}`
                    
                    return (
                            <div id={event.name} key={i}>
                                <div class="center text"><h1 className="border">{event.date}</h1></div>
                                <Content data={event.content } />
                            </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Index;