import React, { useState } from 'react';
import Images from 'components/page/parts/images'

import styles from './Slider.module.sass'
import Icon from 'components/Icon'

const Slider = ({data}) => {
    const [currentImage, setCurrentImage] = useState(0)
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    const showSlide = (direction) => {
        let currentImageNew = direction === 'previous' ? currentImage - 1 : currentImage + 1
        
        /*
        if (currentImageNew < 0) {
            currentImageNew = data.length - 1
        } else if (currentImageNew > data.length - 1) {
            currentImageNew = 0
        }
        */
        
        setCurrentImage(currentImageNew)

        //let image = galleryImages[currentImageNew]

        //history.push({ ...location, search: `?gallery=${image.ID}` })

        //console.log(currentImage)
    }

    /*
    const slides = data.map((slide, i) => (
        <li key={i}><Images data={slide} /></li>
    ))
    */
    
    const slides = []

    for(let i = -2; i<=2; i++) {

        let slideNumber = (currentImage+i) % data.length;
        
        let realSlideNumber 

        if(slideNumber<0) {
            realSlideNumber = data.length + slideNumber
        } else {
            realSlideNumber = slideNumber;    
        }

        let slideData = data[realSlideNumber]

        let test = currentImage+i

        slides.push( <li key={test} style={{left: `${test*100}%`, position: i===0 ? 'relative': 'absolute'}}><Images data={slideData} /></li> )


    }

    let translateX = currentImage * -100;

    if (touching.isTouching) {
        translateX += (touching.touchX / window.innerWidth) * 100;
    }

    return (
        <div className={styles.slider + ' slider'}>
            <div className="buttons">
                <span className="previous" onClick={() => showSlide('previous')}>
                    <Icon type="arrow" />
                </span>
                <span className="next" onClick={() => showSlide('next')}>
                    <Icon type="arrow" />
                </span>
            </div>
            <div className="slides">
                <ul style={{ transform: `translateX(${translateX}%)` }}>{slides}</ul> 
            </div>
        </div>
    );
};

export default Slider;