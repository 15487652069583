import React, { useRef, useState, useEffect } from 'react';
import styles from './Loading.module.sass'

const Loading = () => {
    return (
        <span className={styles.loading}>
           Loading
        </span> 
    )
}

export default Loading