import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Teaserlist.module.sass'

const Index = ({data }) => {
        
    return (
        <div className={styles.teaserlist + ' center'}>
            <div className="sub"></div>
            <div className="main">
                <div className="flex">
                    {data.map((entry, i) => (
                        <div key={i}>
                            <div className>
                                <Link to={entry.path} className="area" />
                                <div className="image" style={{ backgroundImage: `url(${entry.image})`}}></div>
                                <h3>{entry.title}</h3>
                            </div>
                        </div>
                    ))}
                </div>  
            </div>
        </div>
    );
};

export default Index;