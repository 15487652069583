import React, { useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'

import './App.sass';
import { getPage } from './actions'

import Navigation from 'components/navigation'
import Page from 'components/page'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Overlay from 'components/page/contents/overlay'

function App({location, getPage}) {
    const [ pages, setPages ] = useState([])
    const lastPath = useRef(null)

    let currentPath = location.pathname.split('/')[1] || '/'

    if(location.pathname.indexOf('personen') > -1) {
        currentPath = location.pathname
    }
    
    const timeout = { enter: 20000, exit: 100 }


    useEffect(() => {

        if(lastPath.current !== currentPath) {
            lastPath.current = currentPath

            const pagesNew = [...pages, location.pathname]     
            setPages( pagesNew )

            if(pagesNew.length>1) {
                setTimeout(() => {

                    const pagesNew2 = [...pagesNew]
                    pagesNew2.splice(0, 1)
                    setPages( pagesNew2 )
                
                }, timeout.exit);
            }
        }

    }, [location])

    

    console.log(pages)

    const image = location.state && location.state.image ? location.state.image : null

    return (
        <>
            <Navigation />
            <div id="content">
                {pages.map((path, i) => {

                    let type = 'in'
                    let style = {}
                    
                    console.log(i, pages.length-1)

                    if(i<pages.length-1) {
                        console.log('out')
                        type = 'out'
                        style = {marginTop: `-${window.pageYOffset}px`}
                    }
                    
                    return <Page path={path} key={path} type={type} style={style} />

                })}
            </div>
            <Overlay
                active={!!image}
            />
        </>
    );

    /*
    return (
        <>
            <Navigation />
            <TransitionGroup component="div" id="content">
                <CSSTransition
                    key={currentKey}
                    timeout={timeout}
                    classNames="animate-page"
                    
                    unmountOnExit
                    >
                    <Switch>
                            <Route
                                path="/:level1?/:level2?/:level3?"
                                render={(props) => <Page {...props} />}
                            />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
    */
}

const mapStateToProps = (state, props) => {
    const { pages } = state.pages
    const { colorSecondary } = state.config
	//const data = pages.find(page => page.path === props.location.pathname) 

	return {
        colorSecondary
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)), 
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
