import React, { useState, useEffect} from 'react';
import styles from './Home.module.sass'
import LazyImage from 'components/LazyImage'
import { ScaleSize } from 'Functions'

const Index = ({data}) => {

    const calculateScale = () => {
        return window.innerWidth > 1440 ? 1 : window.innerWidth /1440
    }

    const [ scale, setScale] = useState(calculateScale())

  

    useEffect(() => {
		window.addEventListener('resize', resize)

		return () => {
			window.removeEventListener('resize', resize)
        }
	}, [])

	const resize = () => {
		setScale(calculateScale)
    }
    
    const style = {
        fontSize: ScaleSize(100, 45, scale),
        lineHeight: ScaleSize(100, 45, scale)    
    }

    return (
        <div className={styles.home}>
            <LazyImage url={data.image} shouldLoad withFadeIn />
            <h1 style={style}>{data.text}</h1>
        </div>
    );
};

export default Index;