import React from 'react';
import { withRouter } from 'react-router-dom'
import styles from './Images.module.sass'
import LazyImage from 'components/LazyImage'

const Images = ({data, history, location}) => {

    const clickImage = (image) => {
        history.push({...location, state: {image} })
    }

    const images = data.map((image, i) => {

        let style 

        if(data.length===1 && image.height >= image.width) {
            let paddingBottom =  image.height/image.width

            let width = 480/image.height*image.width/851

            return <div className="image" key={i} style={{width: `${width*100}%`}}>
                <div style={{paddingBottom: `${paddingBottom*100}%`}} onClick={() => clickImage(image)}>
                    <LazyImage shouldLoad url={image.medium} withFadeIn />
                </div>
                {image.text &&
                    <p dangerouslySetInnerHTML={{__html: image.text}}></p>
                }
            </div>

        } else if(data.length===1) {

            return <div className="image" key={i} style={{width: '100%'}}>
            <div style={{paddingBottom: `56.4%`}} onClick={() => clickImage(image)}>
                <LazyImage shouldLoad url={image.medium} withFadeIn />
            </div>
            {image.text &&
                <p dangerouslySetInnerHTML={{__html: image.text}}></p>
            }
        </div>

        } else if(data.length===2) {
            return <div className="image" key={i}>
                <div onClick={() => clickImage(image)}>
                    <LazyImage shouldLoad url={image.medium} withFadeIn />
                </div>
                {image.text &&
                    <p dangerouslySetInnerHTML={{__html: image.text}}></p>
                }
            </div>
        }

    })

    const classNames = [styles.images, 'images']
    data.length === 1 && classNames.push('single')
    data.length === 2 && classNames.push('double')
 
    return (
        <div className={classNames.join(' ')}>
            <div>
                {images}
            </div>
        </div>
    );
};

export default withRouter(Images);