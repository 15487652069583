import React, { useState, useEffect, useRef} from 'react';
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import styles from 'components/page/Page.module.sass'
import Location from './Location'

const Index = ({location, places}) => {
    const [ pages, setPages ] = useState([])
    const lastPath = useRef(null)

    let currentPath = location.pathname.split('/')[2] || '/'
    
    const timeout = { enter: 20000, exit: 100 }

    useEffect(() => {

        if(lastPath.current !== currentPath) {
            lastPath.current = currentPath

            const pagesNew = [...pages, location.pathname]     
            setPages( pagesNew )

            if(pagesNew.length>1) {
                setTimeout(() => {

                    const pagesNew2 = [...pagesNew]
                    pagesNew2.splice(0, 1)
                    setPages( pagesNew2 )
                
                }, timeout.exit);
            }
        }

    }, [location])

    const isActive = (path, i) => {

        if(location.pathname==='/orte/' && i===0) {
            return true;
        }

		if(location.pathname.indexOf(path)!==-1) {
			return true;
        }

	}

    return (
    
        <div className="center">
            <div className="sub">
                <ul>
                    {places.map((place, i) => (
                        <li key={i}>
                            <NavLink
                                isActive={() => isActive(place.path, i)}
                                activeClassName="active" 
                                to={place.path}>{place.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <div className="inner">
                    {pages.map((path, i) => {

                        let type = 'in'
                        let style = {}

                        console.log(i, pages.length-1)

                        if(i<pages.length-1) {
                            console.log('out')
                            type = 'out'
                            style = {marginTop: `-${window.pageYOffset}px`}
                        }

                        return <Location path={path} key={path} type={type} style={style} />

                    })}
                </div>
            </div>
        </div>
           
    );
};

const mapStateToProps = (state, props) => {
    const { places } = state.config
    
	return {
        places
	}
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Index));