import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { configConstants } from '../constants';
import { fetchPage } from '../api';

export function* handleConfigLoad(payload) {
    console.log(payload)
    try {
        yield console.log('config')
        //const page = yield select(getPage);
        const data = yield call(fetchPage, '/?config');
        
        //yield put(setImages(images));
        yield put({ type: configConstants.GETCONFIG_SUCCESS, data })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export default function* watchConfigLoad() {
    yield takeEvery(configConstants.GETCONFIG_REQUEST, handleConfigLoad);
}