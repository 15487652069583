import { configConstants } from '../constants';

const initialState = {	
    navigation: {
        main: [],
        meta: []
    },
    places: []
}

export function config(state = initialState, action) {
    switch (action.type) {
        case configConstants.GETCONFIG_REQUEST:
            console.log('ss')
            return {
                ...state
            }
        case configConstants.GETCONFIG_SUCCESS:

            
            console.log(action)

            return {
                ...state,
                navigation:  action.data.navigation,
                places: action.data.places
            }
        case configConstants.GETCONFIG_FAILURE:
            console.log('ss')
            return { 
                error: action.error
            }

        default:
            return state
    }
}