import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'

function LazyImage({shouldLoad, url, asImg, withFadeIn}) {
	const [ loaded, setLoaded ] = useState(false)
    
    let loading = useRef(null)

	useEffect(() => {
		if(shouldLoad && !loading.current) {
            loadImage()
        }
	}, [shouldLoad])
	
	const loadImage = () => {
        if(!loading.current) {
            loading.current = true
            var i = new Image();
            i.onload = () => {
                setLoaded(true)
            };
            i.src = url;
        }
    }
    
    if(withFadeIn) {
        if(asImg) {
            return <img src={url} className={'fadeIn ' + (loaded ? 'show' : '')} />
        } else {
            return <div className={'image fadeIn ' + (loaded ? 'show' : '')} style={{backgroundImage: `url(${url})`}} />
        }
        
    }

    if(!loaded) return null

    if(asImg) return <img src={url} />

	return <div className="image" style={{backgroundImage: `url(${url})`}} />
}

export default LazyImage