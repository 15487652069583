const ScaleSize = (size, min, scale, unit) => {
    unit = unit ? unit : 'px'
    return size*scale > min ? `${size*scale}${unit}` : `${min}${unit}`
}

const RichTextClick = (e, location, history) => {
    //console.log('click')

    if(e.target.tagName === 'A') {
        

        //console.log();
        
        let url = e.target.getAttribute('href').replace(/^.*\/\/[^\/]+/, '')
        let href = e.target.getAttribute('href')

       //console.log(href.split('/').pop().indexOf('.'))

        

        if(href.indexOf('@')>-1 || href.split('/').pop().indexOf('.') > -1) {

            //console.log('special')

        } else if(href.indexOf('747studios.de/')!==-1 ) {

            e.preventDefault()

            if(e.target.getAttribute('rel')==='overlay') {
                history.push({...location, search: `?page=${url}` })
            } else if(e.target.getAttribute('target')==='_blank') {
                window.open(href)
            } else {
                history.push(url)
            };
               
        } else {

            e.preventDefault()

            window.open(href)
        }
                    
    }
    //console.log(e.target)
}

export { 
    ScaleSize,
    RichTextClick
}