import React from 'react';
import styles from './Text.module.sass'

import Content from 'components/page/parts/content'

const Index = ({ data, theme }) => {
    return (
        <div className={styles.text + ' center'}>
            <div className="sub"></div>
            <div className="main">
                <Content data={data} theme={theme} />
            </div>
        </div>
    );
};

export default Index;